<template>
  <div class="menu-left">
    <div class="icon-action" @click="isCollapseData()">
      <span v-if="isCollapse == true"><i class="el-icon-s-unfold"></i></span>
      <span v-else><i class="el-icon-s-fold"></i></span>
    </div>
    <el-menu :default-active="path" class="menu-vertical" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
      <el-submenu class="avatar-left" index="">
        <template slot="title">
          <div class="d-flex align-items-center">
            <img class="image-left" v-if="accountInfo.avatar_path" :src="accountInfo.avatar_path" alt="" />
            <img v-else src="" alt="" class="image-left">
            <div class="d-flex flex-column info-acc">
              <span>Tài Khoản</span>
              <span style="font-weight: 700;">{{ this.accountInfo.name }}</span>
            </div>
          </div>
        </template>
        <el-menu-item-group>
          <el-menu-item @click="nextRoute('account-profile', '/profile')">Thông tin tài khoản</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <!-- QUẢN LÝ CÔNG TY -->
      <router-link :to="{ name: 'company-management' }">
        <el-menu-item index="4">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 21H7C3 21 2 20 2 16V8C2 4 3 3 7 3H17C21 3 22 4 22 8V16C22 20 21 21 17 21Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 8H19" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 12H19" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17 16H19" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.49994 11.29C9.49958 11.29 10.3099 10.4796 10.3099 9.48001C10.3099 8.48038 9.49958 7.67001 8.49994 7.67001C7.50031 7.67001 6.68994 8.48038 6.68994 9.48001C6.68994 10.4796 7.50031 11.29 8.49994 11.29Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 16.33C11.86 14.88 10.71 13.74 9.26 13.61C8.76 13.56 8.25 13.56 7.74 13.61C6.29 13.75 5.14 14.88 5 16.33" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Quản lý công ty</span>
        </el-menu-item>
      </router-link>
      <!-- DANH SÁCH THÔNG TIN XÁC THỰC -->
      <router-link :to="{ name: 'identifier-list' }">
        <el-menu-item index="5">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.59 10.55V7.12C20.59 5.89 19.65 4.53 18.5 4.1L13.51 2.23C12.68 1.92 11.32 1.92 10.49 2.23L5.49997 4.11C4.34997 4.54 3.40997 5.9 3.40997 7.12V14.55C3.40997 15.73 4.18997 17.28 5.13997 17.99L9.43997 21.2C10.14 21.74 11.07 22 12 22" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.9955 21H21.0045" stroke="#5F616E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Thông tin xác thực</span>
        </el-menu-item>
      </router-link>
      <!-- GÓI -->
      <router-link :to="{ name: 'pack-list' }">
        <el-menu-item index="6">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.7 18.98H7.29995C6.87995 18.98 6.40995 18.65 6.26995 18.25L2.12995 6.66999C1.53996 5.00999 2.22996 4.49999 3.64996 5.51999L7.54995 8.30999C8.19995 8.75999 8.93995 8.52999 9.21995 7.79999L10.98 3.10999C11.54 1.60999 12.47 1.60999 13.03 3.10999L14.79 7.79999C15.07 8.52999 15.81 8.75999 16.45 8.30999L20.11 5.69999C21.67 4.57999 22.42 5.14999 21.78 6.95999L17.74 18.27C17.59 18.65 17.12 18.98 16.7 18.98Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.5 22H17.5" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.5 14H14.5" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Gói</span>
        </el-menu-item>
      </router-link>
      <!-- LỊCH SỬ THANH TOÁN -->
      <router-link :to="{ name: 'payment-history' }">
        <el-menu-item index="7">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 6V18" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Lịch sử thanh toán</span>
        </el-menu-item>
      </router-link>
      <!-- Tài liệu sản phẩm -->
      <router-link :to="{ name: 'api-document' }" :class="{
        'router-link-active': activeApiDocument
      }">
        <el-menu-item index="8">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 16.7399V4.66994C22 3.46994 21.02 2.57994 19.83 2.67994H19.77C17.67 2.85994 14.48 3.92994 12.7 5.04994L12.53 5.15994C12.24 5.33994 11.76 5.33994 11.47 5.15994L11.22 5.00994C9.44 3.89994 6.26 2.83994 4.16 2.66994C2.97 2.56994 2 3.46994 2 4.65994V16.7399C2 17.6999 2.78 18.5999 3.74 18.7199L4.03 18.7599C6.2 19.0499 9.55 20.1499 11.47 21.1999L11.51 21.2199C11.78 21.3699 12.21 21.3699 12.47 21.2199C14.39 20.1599 17.75 19.0499 19.93 18.7599L20.26 18.7199C21.22 18.5999 22 17.6999 22 16.7399Z" stroke="#9D62F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 5.48999V20.49" stroke="#9D62F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.75 8.48999H5.5" stroke="#9D62F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 11.49H5.5" stroke="#9D62F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Tài liệu sản phẩm</span>
        </el-menu-item>
      </router-link>
      <!-- BÁO CÁO TIGM KIẾM CỦA KHÁCH HÀNG  -->
      <router-link :to="{ name: 'report-client-search' }">
        <el-menu-item index="8">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#9D62F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.33008 14.49L9.71008 11.4C10.0501 10.96 10.6801 10.88 11.1201 11.22L12.9501 12.66C13.3901 13 14.0201 12.92 14.3601 12.49L16.6701 9.51001" stroke="#9D62F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Báo cáo tìm kiếm của KH</span>
        </el-menu-item>
      </router-link>
      <!-- BÁO CÁO THEO TỪ KHÓA -->
      <router-link :to="{ name: 'report-key-search' }">
        <el-menu-item index="9">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10.74V13.94" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 9V15.68" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17 10.74V13.94" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Báo cáo theo từ khóa</span>
        </el-menu-item>
      </router-link>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeIndex",

  metaInfo() {
    return {
      title: "Menu",
    };
  },

  data() {
    return {
      loading: true,
      isCollapse: true,
    };
  },

  computed: {
    ...mapGetters("account", ["logged", "accountInfo"]),
    ...mapGetters(["path", "full_path"]),
    activeApiDocument() {
      const routeName = this.$route.name;
      return routeName === "api-document" || routeName === "api-create" || routeName === 'api-edit';
    },
  },

  methods: {
    isCollapseData() {
      this.isCollapse = !this.isCollapse;
      this.$emit("toggleMenu", this.isCollapse);
    },
    handleOpen(key, keyPath) {
      console.log('handleOpen');
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log('handleClose');
      console.log(key, keyPath);
    },
    nextRoute(name, full_path) {
      if (this.full_path !== full_path) {
        this.$router.push({ name: name });
      }
    },
  },
  watch: {},
};
</script>

<style lang="css" scoped>
.icon-action {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menu-vertical:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.icon-left {
  margin-right: 10px;
}
</style>
<style>

.menu-vertical {
  border: none !important;
}
.menu-vertical .menu-vertical {
  font-size: 12px;
}
.active {
  color: #9D62F5;
}

/*****************/
/**** EL MENU ****/
/*****************/
.el-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.el-menu > a {
  text-decoration: unset !important;
}

.el-menu svg path {
  stroke: rgb(95, 97, 110);
}

.el-menu .router-link-active .el-menu-item,
.el-menu .router-link-active svg path {
  font-weight: 500;
  color: #9D62F5;
  stroke: #9D62F5;
}

.el-menu svg {
  margin-right: 8px;
}

.el-submenu .el-menu-item {
  min-width: unset !important;
}

.el-menu-item.is-active {
  color: #9D62F5;
}

/*****************/

.menu-vertical .el-menu-item:hover {
  background: #fff;
}
.menu-vertical .el-menu-item.is-active {
  background: #fff;
  color: #303133;
}
.image-left {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #9D62F5;
}
.avatar-left {
  padding: 5px 0;
  border-bottom: 1px solid #F2F3F7;
  border-top: 1px solid #F2F3F7;

  margin-bottom: 10px !important;
}
.avatar-left .el-submenu__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 17px !important;
}
.info-acc {
  padding-left: 10px;
  font-size: 13px !important;
  /* font-weight: 500; */

}
.info-acc span {
  line-height: 25px;
}
.menu-vertical .el-menu-item {
  /* font-weight: 500; */
  font-size: 13px !important;

}
.menu-vertical .el-menu-item, .el-submenu__title {
  line-height: normal;
}
/* .avatar-left img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
} */
</style>
